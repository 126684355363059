<mat-tab-group #tabGroup>

  <mat-tab label="Välkommen">
    <spb-welcome (handleSwipeLeft)="swipeLeft()"
    ></spb-welcome>
  </mat-tab>

  <mat-tab label="Skanna">
    <ng-template matTabContent>
      <spb-scanner (handleSwipeLeft)="swipeLeft()"
                   (handleSwipeRight)="swipeRight()"
      ></spb-scanner>
    </ng-template>
  </mat-tab>

  <mat-tab label="Utförda">
    <ng-template matTabContent>
      <spb-id-list
        (handleSwipeLeft)="swipeLeft()"
        (handleSwipeRight)="swipeRight()"
      ></spb-id-list>
    </ng-template>
  </mat-tab>
  <mat-tab label="Hjälp">
    <spb-help (handleSwipeRight)="swipeRight()"
    ></spb-help>
  </mat-tab>
</mat-tab-group>
