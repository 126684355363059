<div class="holder"
     (swipeleft)="handleSwipeLeft.emit()" (swiperight)="handleSwipeRight.emit()" style="min-height: 50vh"
>
  <div class="text-holder">
    <h2>Välkommen till Sparbanken Syds tjänst för verifiering av Digitalt ID-kort</h2>
    <p>Här kan du enkelt skanna kunders digitala ID-kort från BankID. Genom att skanna den rörliga QR-koden i kundens
      app så uppfylls kraven på en styrkt och tillförlitlig identifiering av kunden. I tjänsten får du även en överblick
      över dina tidigare utförda verifieringar.</p>

    <p>Tjänsten är ett hjälpmedel för att vi tillsammans ska säkerställa en hög säkerhet och kundservice. Vi strävar
      efter att göra ditt jobb enklare och mer effektivt.</p>

  </div>
</div>
