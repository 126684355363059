import {IQrScannerFactory} from './qr-scanner.provider'
import {ElementRef} from '@angular/core'
import QrScanner from 'qr-scanner'

export class QrFactory implements IQrScannerFactory {
  public getScanner(element: ElementRef, callback: (r: any) => void, options: any): QrScanner {
    return new QrScanner(element as unknown as HTMLVideoElement, callback, options)
  }

  public listCameras(requestLabels?: boolean): Promise<Array<QrScanner.Camera>> {
    return QrScanner.listCameras(requestLabels)
  }
}
